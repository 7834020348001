import React, { useState, useEffect } from "react";
import { Routes, Route, Switch, withRouter } from "react-router-dom";
import request from "request"
import Flash from "flash"
import { isValidPhoneNumber } from 'libphonenumber-js'

import { padStart, splice } from "lodash"

import Card from "Card"
import Icon from "Icon"
import Input from "Input"
import Modal from "Modal"
import { SkewLoader } from "react-spinners"
import Button from "Button"
import PhoneInput from 'react-phone-number-input/input'

const PaymentView = ({order, terminal, orderComplete, isPaymentActive, setIsPaymentActive}) => {
  const [ currentView, setCurrentView ] = useState(null)
  const [ error, setError ] = useState({})
  const [ checkPaymentInterval, setCheckPaymentInterval ] = useState(null)
  const [ cashAmount, setCashAmount ] = useState("")
  const [ changeDue, setChangeDue ] = useState("$0.00")
  const [ isSendingToReader, setIsSendingToReader ] = useState(false)
  const [ isPaymentComplete, setIsPaymentComplete ] = useState(false)
  const [ isCompletingOrder, setIsCompletingOrder ] = useState(false)
  const [ isSendingReceipt, setIsSendingReceipt ] = useState(false)
  const [ receiptType, setReceiptType ] = useState("")
  const [ hasSentReceipt, setHasSentReceipt ] = useState(false)
  const [ lastCompletedOrder, setLastCompletedOrder ] = useState({})

  const [ phone, setPhone ] = useState("")

  const closeModal = () => {
    if(isCompletingOrder) return false

    setIsPaymentActive(false)
    setCurrentView(null)
    setError({})
    setLastCompletedOrder({})
    request.post(`/terminals/${terminal.id}/reader/clear`)
  }

  const setCardPayment = () => {
    if(!order || !order.id) return false

    setCurrentView("card")
    setIsSendingToReader(true)
    const req = request.post(`/carts/${order.id}/send_to_reader`)
    req.then(({data}) => {
      setIsSendingToReader(false)
      const paymentCheck = setInterval(() => checkPayment(data.paymentId), 500)
      setCheckPaymentInterval(paymentCheck)
    })

    req.catch(({response}) => {
      const message = errorMessageFor(response.data.error || "reader")
      setError({message, type: "reader"})
      setCurrentView(null)
      setIsSendingToReader(false)
    })
  }

  const checkPayment = (paymentId) => {
    if (!paymentId) return false

    const req = request.get(`/payments/${paymentId}`)
    req.then(({data}) => {
      if(data.payment.status == "success") {
        setCurrentView("complete")
        setLastCompletedOrder(data.order)
        setError({})
        setCashAmount("")
      } else if(data.payment.status == "failed") {
        setCurrentView(null)
        setError({type: "payment", message: data.payment.failureReason})
      }
    })
  }

  const clearCheck = () => clearInterval(checkPaymentInterval)
  useEffect(clearCheck, [ currentView, isPaymentActive ])

  const errorMessageFor = (code) => {
    switch(code) {
      case "terminal_reader_offline":
        return "Card reader is offline. Please check card reader power and connectivity"
      case "order_paid":
        return "This order has already been paid for. Please clear the order and start a new one"
      case "terminal_reader_timeout":
        return "There was a timeout sending this payment to the reader. Please try again"
      case "reader":
        return [
          "There was an issue requesting payment on the card reader. Please try again",
          "If the issue persists please reach out to Catch Support"
        ].join("\n\n")
      default:
        return "An unknown error occured"
    }
  }

  const addAmount = (amount) => {
    const newAmount = `${cashAmount}${amount}`
    const changeAmount = formatCurrency((parseInt(newAmount) - order.totalCents))
    setCashAmount(newAmount)
    setChangeDue(changeAmount)
  }

  const formatCurrency = (amount) => {
    const paddedAmount = padStart(amount, 3, "0")
    const decimalPosition = paddedAmount.length - 2
    return "$" + paddedAmount.slice(0, decimalPosition) + "." + paddedAmount.slice(decimalPosition)
  }

  const submitPayment = () => {
    setIsCompletingOrder(true)
    const req = request.post("payments", { orderId: order.id })
    req.then(({data}) => {
      setCurrentView("complete")
      setError({})
      setIsCompletingOrder(false)
      setLastCompletedOrder(data.order)
      setCashAmount("")
    })
    req.catch(() => {
      setIsCompletingOrder(false)
      setLastCompletedOrder({})
      Flash.broadcast("error", "Something bad happened....")
    })
  }

  const startNewOrder = () => {
    setError({})
    setIsPaymentActive(false)
    setCurrentView(null)
    setHasSentReceipt(false)
    setChangeDue("$0.00")
    setReceiptType("")
    setPhone("")
    orderComplete()
  }

  const printReceipt = () => {
    setIsSendingReceipt(true)
    const req = request.post(`/orders/${order.id}/print_receipt`)
    req.then(({data}) => {
      setIsSendingReceipt(false)
      setHasSentReceipt(true)
      setReceiptType("print")
    })
  }

  const textReceipt = () => {
    setIsSendingReceipt(true)
    setReceiptType("text")
    const req = request.post(`/orders/${order.id}/receipt`, {
      phone: phone,
      type: "text",
    })
    req.then(() => {
      Flash.broadcast("success", "Receipt Sent")
      setPhone("")
    })
    req.catch(() => {
      Flash.broadcast("error", "There was an issue sending the receipt")
    })
    req.finally(() => {
      setIsSendingReceipt(false)
      setHasSentReceipt(true)
      setReceiptType("")
    })
  }

  return(
    <div>
      <Modal
        title={
          <div className="is-flex is-align-items-center">
            { currentView === "complete" ? (
              <>
                <h3 className="is-size-3 is-centered mb-0">
                  <Icon icon="check-circle" className="text-success mr-3" />
                  Order Complete
                </h3>
                { changeDue != "$0.00" && <p className="subtitle ml-auto is-size-4 m-0">Change Due {changeDue}</p> }
              </>
            ) : (
              <h3 className="is-size-3 is-centered mb-0">
                <Icon icon="cash-register" className="mr-3" />
                Payment
              </h3>
            )}
          </div>
        }
        open={isPaymentActive}
        actions={
          <>
            { currentView != "complete" && <Button text="Cancel" color="red" onClick={closeModal} /> }
            { currentView == "complete" && <Button text="New Order" className="is-fullwidth is-size-4" onClick={startNewOrder} /> }
          </>
        }
      >

        {{
          null: <div>
            { error.type &&
              <p className="notification has-text-centered is-danger mb-4 has-text-weight-bold">
                { error.type == "payment" &&
                  <>
                    Error Processing Payment
                    <br/>
                  </>
                }
                { error.message }
              </p>
            }
            <div className="is-flex">
              <Button
                text={<><Icon icon="credit-card" className="mr-2"/> Card</>}
                className="is-fullwidth is-size-4 mr-1"
                onClick={setCardPayment}
              />
              <Button
                text={<><Icon icon="money-bill" className="mr-2" /> Cash</>}
                className="is-fullwidth is-size-4 ml-1"
                onClick={() => setCurrentView("cash")}
              />
            </div>
          </div>,
          "cash": <div>
            <div className="columns">
              <div className="column">
                <p className="subtitle">Order Total: ${order?.total}</p>
                <div className="columns">
                  <div className="column is-bordered">
                    <div className="field">
                      <label className="label">Cash Amount</label>
                      <input
                        type="text"
                        readOnly
                        value={formatCurrency(cashAmount)}
                        className="input is-size-4"
                      />
                    </div>
                  </div>
                  <div className="column is-narrow">
                    <div className="field">
                      <span className="label">&nbsp;</span>
                      <Button
                        onClick={() => setCashAmount("")}
                        text="Clear"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="columns is-multiline is-centered">
                  { [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "00"].map((amount, i) => {
                    return(
                      <div
                        key={i}
                        className="column is-one-third is-primary p-1"
                      >
                        <Button
                          className="is-fullwidth is-size-4"
                          onClick={() => addAmount(amount)}
                          text={amount}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="is-flex mt-4">
              <Button
                className="is-size-4"
                color="red"
                onClick={() => {
                  setCashAmount("")
                  setChangeDue("$0.00")
                  setCurrentView(null)
                }}
                text="Back"
              />
              <Button
                className="ml-auto is-size-4"
                onClick={() => setCurrentView("change")}
                disabled={(order?.totalCents || 0) - parseInt(cashAmount || "0") > 0}
                text="Next"
              />
            </div>
          </div>,
          "change": <div>
            <p className="subtitle">Cash Amount {formatCurrency(cashAmount)}</p>
            <p className="subtitle">Order total ${order?.total}</p>
            <p className="subtitle">Change Due {changeDue}</p>

            <div className="is-flex mt-4">
              <Button
                color="red"
                className="is-size-4"
                onClick={() => setCurrentView("cash")}
                loading={isCompletingOrder}
                text="Back"
              />
              <Button
                color="green"
                className="ml-auto is-size-4"
                onClick={submitPayment}
                loading={isCompletingOrder}
                text="Complete Order"
              />
            </div>
          </div>,
          "card": <div className="has-text-centered">
            <h2 className="title">
              { isSendingToReader ? "Sending to Reader" : "Awaiting Payment...." }
            </h2>
            <div className="my-4">
              <SkewLoader size={40} color="#F9690E" />
            </div>
          </div>,
          "complete": <div>
            <h4 className="is-size-4">
              Order #{lastCompletedOrder?.number}
            </h4>
            <p className="subtitle label is-size-4 mt-4">Receipt Options</p>
            <div className="mb-4">
              <p className="label is-size-5">Text Receipt:</p>
              <div className="is-flex is-align-items-center">
                <PhoneInput
                  country="US"
                  name="phone"
                  onChange={setPhone}
                  value={phone}
                  className="input"
                />
                <Button
                  text="Send"
                  disabled={!isValidPhoneNumber(phone || "")}
                  className="ml-2 is-size-5"
                  loading={isSendingReceipt && receiptType === "text"}
                  color={hasSentReceipt && receiptType === "text" ? "green" : "link"}
                  onClick={textReceipt}
                />
              </div>
            </div>
            { terminal.hasPrinter &&
              <Button
                text={hasSentReceipt && receiptType === "print" ? "Receipt Sent to Printer" : "Print Reciept"}
                className="is-fullwidth mr-2 is-size-5"
                loading={isSendingReceipt && receiptType === "print"}
                disabled={hasSentReceipt && receiptType === "print"}
                color={hasSentReceipt && receiptType === "print" ? "green" : "link"}
                onClick={printReceipt}
              />
            }

          </div>,
        }[currentView]}
        <div>
        </div>
      </Modal>
    </div>
  )
}

export default PaymentView
