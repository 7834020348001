import React, { useState, useEffect } from "react";
import { Routes, Route, Switch, withRouter } from "react-router-dom";
import request from "request"
import Terminal from "lib/auth/Terminal"

import CustomAmountModal from "./CustomAmountModal"

import Form from "Form"
import Input from "Input"
import Button from "Button"

import ProductListView from "./ProductListView"
import ProductGridView from "./ProductGridView"

const ItemsList = ({terminal, store, selectProduct, addProduct, isModifyingOrder}) => {
  const [ isLoaded, setIsLoaded ] = useState(false)
  const [ isCustomAmountOpen, setIsCustomAmountOpen ] = useState(false)
  const [ categories, setCategories ] = useState([])
  const [ products, setProducts ] = useState([])
  const [ currentView, setCurrentView ] = useState(Terminal.getLayout() || "list")

  const fetchMenu = () => {
    setIsLoaded(false)
    const req = request.get(`/menus/${store.id}`)
    req.then(({data}) => {
      setCategories(data.categories)
      setProducts(data.products)
      setIsLoaded(true)
    })
  }

  useEffect(fetchMenu, [])

  const quickAddProduct = (productId) => addProduct(productId, 1, [])
  const categoryProducts = (catId) => products.filter((p) => p.categoryId == catId)

  const switchView = () => {
    const newView = currentView === "list" ? "grid" : "list"
    setCurrentView(newView)
    Terminal.setLayout(newView)
  }

  return(
    <div className="MenuWrapper is-flex is-flex-direction-column pt-4">
      <div className="is-flex">
        <Button
          onClick={fetchMenu}
          loading={!isLoaded || isModifyingOrder}
          text="Refresh Menu"
        />
        <Button
          className="ml-4"
          onClick={switchView}
          text={`${currentView === "list" ? "Grid" : "List" } View`}
        />
        <Button
          className="ml-auto is-outlined has-text-weight-bold"
          color="link"
          loading={!isLoaded || isModifyingOrder}
          onClick={() => setIsCustomAmountOpen(true)}
          text="Add Custom Amount"
        />
      </div>

      { currentView === "list" &&
        <ProductListView
          products={products}
          categories={categories}
          selectProduct={selectProduct}
          quickAddProduct={quickAddProduct}
          isModifyingOrder={isModifyingOrder}
        />
      }

      { currentView === "grid" &&
        <ProductGridView
          products={products}
          categories={categories}
          selectProduct={selectProduct}
          isModifyingOrder={isModifyingOrder}
        />
      }

      <CustomAmountModal
        terminal={terminal}
        addProduct={addProduct}
        isOpen={isCustomAmountOpen}
        setIsOpen={setIsCustomAmountOpen}
      />
    </div>
  )
}

export default ItemsList

