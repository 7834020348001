import React, { useState } from "react";
import { Routes, Route, Switch, withRouter } from "react-router-dom";
import request from "request"

import Card from "Card"
import Icon from "Icon"
import Button from "Button"

const OrderView = ({
  store,
  order,
  clearOrder,
  removeProduct,
  isModifyingOrder,
  setIsPaymentActive,
  chargesCustomerFees,
}) => {
  const [ isLoading, setIsLoading ] = useState(false)

  const requestPayment = () => {
    if(!order || !order.id) return false

    setIsPaymentActive(true)
  }

  return(
    <div className="OrderView">
      <Card title="Order">
        <div>
          <table className="table is-compact is-fullwidth">
            <thead>
              <tr>
                <th>Qty</th>
                <th>Product</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              { !order || order.products.length == 0 ? (
                <tr><td colSpan="3" className="my-2 is-italic has-text-centered">Order is Empty</td></tr>
              ) : (
                order.products.map((lineItem, i) => {
                  return(
                    <tr key={"li" + i}>
                      <td className="is-narrow">
                        { lineItem.quantity }
                      </td>
                      <td style={{width: "100%"}}>
                        <p className="has-text-weight-bold">
                          { lineItem.name == "Custom Amount" ? [ lineItem.name, lineItem.price ].join(" $") : lineItem.name }
                        </p>
                        { lineItem.modifiers.length > 0 &&
                          <p>
                            { lineItem.modifiers.map((m) => m.name).join(", ") }
                          </p>
                        }
                      </td>
                      <td>
                        <Button
                          onClick={() => removeProduct(lineItem.id, lineItem.quantity)}
                          color="red"
                          className="is-outlined"
                          text={<Icon icon="trash" />}
                          disabled={isModifyingOrder}
                        />
                      </td>
                    </tr>
                  )
                })
              )}
            </tbody>
          </table>

          <div className="has-text-right mb-4">
            <p>
              <span className="has-text-weight-bold">Items Subtotal</span>
              <span className="ml-2">${ order?.subtotal || "0.00" }</span>
            </p>
            <p>
              <span className="has-text-weight-bold">Sales Tax</span>
              <span className="ml-2">${ order?.taxes || "0.00" }</span>
            </p>
            <p>
              <span className="has-text-weight-bold">Total</span>
              <span className="ml-2">${ order?.total || "0.00" }</span>
            </p>
          </div>

          <div className="is-flex">
            <Button
              onClick={clearOrder}
              text="Clear Order"
              className="is-outlined"
              color="red"
              disabled={!(order?.products?.length > 0)}
            />
            <Button
              onClick={requestPayment}
              disabled={!order || order.total == "0.00"}
              text="Process Payment"
              color="green"
              className="ml-auto"
            />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default OrderView

