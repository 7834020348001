import React, { useState, useEffect } from "react";
import { Routes, Route, Switch, withRouter } from "react-router-dom";
import request from "request"

import formatCurrency from "utils/formatCurrency"

import { SkewLoader } from "react-spinners"
import { xor, intersection, sum } from "lodash"

import Icon from "Icon"
import Input from "Input"
import Modal from "Modal"
import Button from "Button"

const ProductView = ({addProduct, isModifyingOrder, selectedProduct, clearProduct}) => {
  const [ product, setProduct ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ quantity, setQuantity ] = useState(1)
  const [ selectedModifiers, setSelectedModifiers ] = useState([])

  const fetchProduct = () => {
    if (!selectedProduct) return false

    setIsLoading(true)
    const req = request.get(`store_products/${selectedProduct}`, { params: { view: "terminal" }})
    req.then(({data}) => {
      setProduct(data.product)
      setIsLoading(false)
    })
  }

  useEffect(fetchProduct, [selectedProduct])

  const closeModal = () => {
    setQuantity(1)
    setProduct(null)
    clearProduct()
    setSelectedModifiers([])
  }

  const toggleModifier = (modifierId) => {
    setSelectedModifiers(xor(selectedModifiers, [ modifierId ]))
  }

  const addToOrder = () => {
    if(!product) return false

    addProduct(selectedProduct, quantity, selectedModifiers)
    closeModal()
  }

  const renderModalActions = () => {
    return(
      <div className="is-flex is-flex-grow-1">
        <Button
          onClick={closeModal}
          text="Cancel"
          className="is-outlined"
          color="red"
        />

        <div className="mx-auto is-flex is-align-items-center">
          <Button disabled={quantity == 1} onClick={() => setQuantity(quantity - 1)} text="-"/>
          <span className="tag is-size-6 has-text-weight-bold mr-2">{ quantity }</span>
          <Button onClick={() => setQuantity(quantity + 1)} text="+"/>
        </div>

        <div>
          <Button
            text="Add to Order"
            loading={isModifyingOrder}
            disabled={!(product && product.isActive)}
            onClick={addToOrder}
            color="green"
            className="is-text-weight-bold"
          />
        </div>
      </div>
    )
  }

  return(
    <div className="ProductView">
      <Modal
        open={!!selectedProduct}
        toggleModal={closeModal}
        actions={renderModalActions()}
      >
        { !product &&
          <div className="is-flex is-justify-content-center p-4">
            <SkewLoader size={40} color="#F9690E" />
          </div>
        }
        { product &&
          <div>
            <h3 className="title">
              <span style={{}} className={`mr-2 p-2 ${product.isActive ? "has-background-success" : "has-background-danger"}`} />
              { product.name }
            </h3>
            <h4 className="subtitle mt-2">
              Price {formatCurrency(product.price)}
            </h4>

            { product.modifiers && product.modifiers.map((modifier, i) => {
              return(
                <div key={i} className="ml-2 my-1 columns is-mobile is-justify-content-center" onClick={() => toggleModifier(modifier.id)}>
                  <div className="column is-narrow">
                    <Icon className="is-size-4 m-0" icon={selectedModifiers.indexOf(modifier.id) > -1 ? "check-square" : "square" } />
                  </div>
                  <div className="column">
                    <p className="is-size-5">{ modifier.name }</p>
                  </div>
                  <div className="column is-narrow">
                    { modifier.price != 0 &&
                      (parseFloat(modifier.price) >= 0 ? "+" : "-") + "$" + modifier.price
                    }
                  </div>
                </div>
              )
            })}
          </div>
        }
      </Modal>
    </div>
  )
}

export default ProductView

