import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import wrapComponent from "ApplicationWrapper"
import Terminal from "lib/auth/Terminal"
import { MobileView } from "react-device-detect";

import ProtectedRoute from "ProtectedRoute"
import RotateDevice from "RotateDevice"

import FourOhFour from "FourOhFour"

import LoginView from "Terminal/LoginView"

import Register from "views/Terminal/Register"

export default props => (
  <Router>
    <MobileView>
      <RotateDevice />
    </MobileView>
    <Switch>
      <Route path="/login" exact component={wrapComponent(LoginView, "Auth")} /> }

      <ProtectedRoute path="/" exact index component={wrapComponent(Register, "Terminal")} auth={Terminal} />

      <Route path="/*" component={wrapComponent(FourOhFour)} /> }
    </Switch>
  </Router>
);

