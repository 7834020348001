import React, { useState, useEffect } from "react";
import { Routes, Route, Switch, withRouter } from "react-router-dom";
import request from "request"
import { padStart, splice } from "lodash"

import Form from "Form"
import Input from "Input"
import Modal from "Modal"
import Button from "Button"

const CustomAmountModal = ({isOpen, setIsOpen, terminal, store, addProduct}) => {
  const [ stagedAmount, setStagedAmount ] = useState("")
  const [ isLoading, setIsLoading ] = useState(false)

  const closeModal = () => {
    setIsOpen(false)
    setIsLoading(false)
    setStagedAmount("")
  }

  const addAmount = (amount) => {
    const newAmount = `${stagedAmount}${amount}`
    setStagedAmount(newAmount)
  }

  const formatCurrency = (amount) => {
    const paddedAmount = padStart(amount, 3, "0")
    const decimalPosition = paddedAmount.length - 2
    return "$" + paddedAmount.slice(0, decimalPosition) + "." + paddedAmount.slice(decimalPosition)
  }

  const addToOrder = async () => {
    setIsLoading(true)
    await addProduct("custom", 1, [], stagedAmount)
    closeModal()
  }

  return(
      <Modal
        open={isOpen}
        actions={
          <>
            <Button
              className="ml-auto is-outlined"
              color="red"
              onClick={closeModal}
              text="Cancel"
            />
            <Button
              className=""
              loading={isLoading}
              onClick={addToOrder}
              disabled={stagedAmount == ""}
              text="Add to Order"
            />
          </>
        }
      >
        <div className="mb-4">
          <h3 className="is-size-4">Charge Custom Amount</h3>
        </div>
        <div className="columns">
          <div className="column">
            <div className="columns">
              <div className="column is-bordered">
                <div className="field">
                  <label className="label">Amount to Charge</label>
                  <input
                    type="text"
                    readOnly
                    value={formatCurrency(stagedAmount)}
                    className="input is-size-4"
                  />
                </div>
              </div>
              <div className="column is-narrow">
                <div className="field">
                  <span className="label">&nbsp;</span>
                  <Button
                    onClick={() => setStagedAmount("")}
                    text="Clear"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="columns is-multiline is-centered">
              { [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "00"].map((amount, i) => {
                return(
                  <div
                    key={i}
                    className="column is-one-third is-primary p-1"
                  >
                    <Button
                      className="is-fullwidth is-size-4"
                      onClick={() => addAmount(amount)}
                      text={amount}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Modal>
  )
}

export default CustomAmountModal
