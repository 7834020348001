import React, { useState, useEffect } from "react";
import { Routes, Route, Switch, withRouter } from "react-router-dom";
import request from "request"
import Terminal from "lib/auth/Terminal"

import Form from "Form"
import Input from "Input"

import ItemsList from "./Register/ItemsList"
import ProductView from "./Register/ProductView"
import OrderView from "./Register/OrderView"
import PaymentView from "./Register/PaymentView"

import Flash from "flash"

const Register = () => {
  const [ selectedProduct, setSelectedProduct ] = useState(null)
  const [ order, setOrder ] = useState(null)
  const [ orderId, setOrderId ] = useState(Terminal.getCurrentOrderId())
  const [ productCount, setProductCount ] = useState(0)
  const [ isModifyingOrder, setIsModifyingOrder ] = useState(false)
  const [ isPaymentActive, setIsPaymentActive ] = useState(false)
  const { terminal } = Terminal.getWhoAmI()
  const clearProduct = () => setSelectedProduct(null)

  const clearOrder = () => {
    setOrder(null)
    setOrderId(null)
    setProductCount(0)
    Terminal.clearCurrentOrderId()
  }

  const orderComplete = () => {
    clearOrder()
  }

  const fetchOrder = () => {
    if (orderId == "null" || orderId === null) return false

    const req = request.get(`carts/${orderId}`)
    req.then(({data}) => {
      if(data.error) {
        clearOrder()
      } else {
        setOrder(data.order)
        setProductCount(data.order.products.count)
        setIsModifyingOrder(false)
      }
    })
  }
  useEffect(fetchOrder, [ productCount ])

  const createOrder = async () => {
    if(order) return false

    const orderAttrs = {
      type: "terminal",
      storeId: terminal.store.id,
      terminalId: terminal.id,
    }

    const req = await request.post("carts", orderAttrs)
    return req.data.order.id
  }

  const addProduct = async (productId, quantity, selectedModifiers = [], customPrice = null) => {
    let newOrderId = null
    setIsModifyingOrder(true)

    if(!orderId) {
      newOrderId = await createOrder()

      if (newOrderId) {
        setOrderId(newOrderId)
        Terminal.setCurrentOrderId(newOrderId)
        setProductCount(0)
      }
    }

    const inflightOrderId = newOrderId || orderId

    if (inflightOrderId === "null" || inflightOrderId == null) return setIsModifyingOrder(false)

    const req = request.post(`/carts/${inflightOrderId}/line_items`, {
      productId: productId,
      amount: customPrice,
      line_item: {
        quantity,
        selectedModifiers
      }
    })
    req.then(({data}) => {
      setProductCount(productCount + quantity)
    })
  }

  const removeProduct = (lineItemId, quantity) => {
    setIsModifyingOrder(true)

    const req = request.delete(`/carts/${orderId}/line_items/${lineItemId}`)
    req.then(({data}) => {
      setProductCount(productCount - quantity)
    })
  }

  return(
    <div className="columns">
      <div className="column" style={{maxHeight: "100%", overflowY: "scroll", overflow: "hidden"}}>
        <ItemsList
          terminal={terminal}
          store={terminal.store}
          selectProduct={setSelectedProduct}
          addProduct={addProduct}
          isModifyingOrder={isModifyingOrder}
        />
      </div>
      <div className="column is-one-third mt-4">
        <OrderView
          order={order}
          store={terminal.store}
          clearOrder={clearOrder}
          removeProduct={removeProduct}
          isModifyingOrder={isModifyingOrder}
          setIsPaymentActive={setIsPaymentActive}
          chargesCustomerFees={terminal.chargesCustomerFees}
        />
      </div>

      <ProductView
        clearProduct={clearProduct}
        selectedProduct={selectedProduct}
        isModifyingOrder={isModifyingOrder}
        addProduct={addProduct}
      />

      <PaymentView
        order={order}
        terminal={terminal}
        isPaymentActive={isPaymentActive}
        setIsPaymentActive={setIsPaymentActive}
        orderComplete={orderComplete}
      />

    </div>
  )
}

export default Register
