import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import Terminal from "lib/auth/Terminal"

import Form from "Form"
import Input from "Input"

class LoginView extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  formatValue = (value) => {
    let val = value.replace(/\D/g, "")
    if (val.length > 4) {
      val = [ val.slice(0, 4), val.slice(4) ].join("-")
    }
    this.props.updateField("authCode", val)
  }

  handleLogin = (e) => {
    this.setState({errors: null})
    const { formData } = this.props
    if (!formData.authCode || formData.authCode.length < 9) {
      this.setState({errors: "Auth code is too short"})
    } else {
      let req = request.post("auth/terminal/login", { authCode: formData.authCode })
      req.then(({data}) => {
        Terminal.setAuthToken(data.authToken)
        this.setState({authed: true})
      })
    }
  }

  render() {
    if (this.state.authed) return <Redirect to="/" />

    const { formData } = this.props
    return(
      <div className="LoginView">
        <p className="title has-text-centered">Terminal Login</p>
        <p className="block">Use the authorization code from your admin page to log in here</p>
        <Form className="block" onSubmit={this.handleLogin} noWrapper={true} >
          <Input type="tel" label="Auth Code" onChange={this.formatValue} value={formData.authCode} />

          <div className="block">
            <input type="submit" className="button is-vcentered is-primary is-outlined" value="Login" />
          </div>
          { this.state.errors && (
            <p className="has-text-centered has-text-danger">{ this.state.errors }</p>
          )}
        </Form>
      </div>
    )
  }
}

export default usesFeatures(['form'], LoginView)

