import React, { useState, useEffect } from "react";
import { Routes, Route, Switch, withRouter } from "react-router-dom";
import request from "request"
import { truncate } from "lodash"

import CustomAmountModal from "./CustomAmountModal"

import Form from "Form"
import Input from "Input"
import Button from "Button"

const ProductListView = ({
  products,
  categories,
  selectProduct,
  quickAddProduct,
  isModifyingOrder,
}) => {

  const categoryProducts = (catId) => products.filter((p) => p.categoryId == catId)

  return(
    <div className="StoreMenu">
      { categories.map((category, i) => {
        if(categoryProducts(category.id).length == 0) return null
        if(!category.active) return null

        return(
          <div key={"cat" + i} className="py-2">
            <h3 className="title is-size-4 mb-2"> { category.name } </h3>

            <div className="flex-table">
              { categoryProducts(category.id).map((product, i) => {
                const { isActive } = product
                return(
                  <div key={"prod" + i} className={`flex-table-item`}>
                      <span style={{}} className={`is-absolute p-2 ${isActive ? "has-background-success" : "has-background-danger"}`} />
                    <h5 className="flex-table-cell is-size-5" onClick={() => selectProduct(product.id)}>
                      { truncate(product.name, 50) }
                    </h5>
                    <div className="flex-table-cell" onClick={() => selectProduct(product.id)}>
                      ${ product.price }
                    </div>
                    <div className="flex-table-cell is-flex-grow-0 mr-6">
                      <Button
                        text="Add"
                        disabled={!isActive}
                        className="ml-auto"
                        color="green"
                        onClick={() => selectProduct(product.id) }
                      />
                    </div>
                    <div className="flex-table-cell cell-end is-flex-grow-0">
                      <Button
                        text="Quick Add"
                        disabled={!isActive}
                        loading={isModifyingOrder}
                        className="ml-auto"
                        onClick={() => quickAddProduct(product.id)}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ProductListView


